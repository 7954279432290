<template>
    <section class="section_3">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex flex-column align-items-center justify-content-center text-center">
                    <h2>Economize enquanto dirige</h2>
                    <p>Cadastre-se como condutor e economize nos custos da viagem levando passageiros. São só alguns minutos para oferecer uma carona. Tudo pronto para pegar a estrada?</p>
                    <a class="btn btn-dark button-one" href="">Oferecer carona</a>
                </div>
                <div class="col-md-6">
                    <img src="../../assets/images/carona.svg" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

</script>

<style lang="scss" scoped>
    .section_3 {
        padding: 60px 0;
    }
</style>
import { createApp } from 'vue';
import App from './App.vue';

// Importe o Bootstrap 5 CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/scss/custom.scss';

const app = createApp(App);

app.mount('#app');

<template>
    <footer class="bg-dark">
        <div class="container">
            <div class="row d-flex justify-content-between">
                <div class="col d-flex justify-content-start">
                    <a href="">Termos de Uso</a>
                </div>
                <div class="col d-flex justify-content-end">
                    <p>Blabladriver, 2023 ©</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

</script>

<style lang="scss" scoped>
footer {
    padding: 24px 0;
    color: #ffffff;
}
</style>
<template>
    <section class="section_2 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="../../assets/images/security.svg" alt="">
                </div>
                <div class="col-md-6 d-flex flex-column align-items-center justify-content-center text-white text-center">
                    <h2>Sua segurança é nossa prioridade</h2>
                    <p>Na BlaBlaCar, trabalhamos muito para que nossa plataforma seja a mais segura possível. Mas quando golpes aparecem, queremos que você saiba exatamente como evitar e reportá-los. Siga nossas dicas para nos ajudar a manter você em segurança.</p>
                    <a class="btn btn-light button-one" href="">Saiba mais</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

</script>

<style lang="scss" scoped>
    .section_2 {
        padding: 60px 0;
    }
</style>